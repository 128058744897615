import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";

var _name$props$computed$;

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default (_name$props$computed$ = {
  name: 'nut-textinput',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasBorder: {
      type: Boolean,
      default: true
    },
    outline: {
      type: Boolean,
      default: false
    },
    clearBtn: {
      type: Boolean,
      default: true
    },
    clearBtnPersonnal: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  data: function data() {
    return {
      clearBtnShow: false
    };
  }
}, _defineProperty(_name$props$computed$, "computed", {
  inputListeners: function inputListeners() {
    var vm = this;
    return _extends({}, this.$listeners, {
      input: function input(event) {
        vm.clearBtnShow = !!event.target.value;
        vm.$emit('input', event.target.value);
      }
    });
  }
}), _defineProperty(_name$props$computed$, "watch", {
  value: function value(newVal, oldVal) {
    this.clearBtnShow = !!newVal;
  }
}), _defineProperty(_name$props$computed$, "methods", {
  clear: function clear() {
    this.$emit('input', '');
    this.clearBtnShow = false;
  },
  focus: function focus() {
    this.$nextTick(function () {
      this.$refs.nutUiInput.focus();
    });
  },
  blur: function blur() {
    this.$refs.nutUiInput.blur();
  }
}), _defineProperty(_name$props$computed$, "mounted", function mounted() {
  this.clearBtnShow = !!this.value;
}), _name$props$computed$);