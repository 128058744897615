//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'nut-tabbar',
  props: {
    tabbarList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    bottom: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'based'
    },
    replace: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      currIndex: null,
      tabList: this.tabbarList
    };
  },
  mounted: function mounted() {
    this.initBar();
  },
  watch: {
    tabbarList: {
      handler: function handler(value) {
        this.tabList = value;
        this.initBar();
      },
      deep: true
    }
  },
  methods: {
    initBar: function initBar() {
      var _this = this;

      this.tabList.forEach(function (item, index) {
        if (item.curr) {
          _this.currIndex = index;
        }
      });
    },
    switchTabs: function switchTabs(value, index) {
      this.currIndex = index;

      if (this.replace) {
        //替换url
        window.location.replace(value.href);
      } else {
        if (value.href) {
          window.location.href = value.href;
        }
      }

      this.$emit('tab-switch', value, index);
    }
  }
};